<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'treatmentdaymanagement'}"></work-subject>
        <hr>
        <h3>Tedavi günlerine ait komponent</h3>
        <div style="width: 100%;">
          <img src="../../../public/demo/treatmentdaymanagement.png" width="100%">
        </div>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.schema_drugs">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugs'}"></sql-database>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugsApplication'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as TreatmentListWidget
} from '@/components/widgets/TreatmentListWidget';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentDayManagement',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    TreatmentListWidget,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_treatmentList: require('@/example_data/treatment_list').example_data,
      d_patientData: require('@/example_data/patient_data').example_data,
      d_treatmentData: require('@/example_data/treatment_data').example_data,
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<template v-for="(day, day_index) in d_treatmentData.drug_day_order"> \n <treatment-day :p_treatmentData="d_treatmentData" :p_day="day"></treatment-day> \n </template>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

